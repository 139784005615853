<div class="PropertySheetHeader">
  <div class="title"><span class="mode"></span>{{title}}</div>
  <div class="subtitle">{{subtitle}}</div>
</div>

<table [class.threecolumns]="asset && asset.dto == 'ObservableInventory_v1'" >
  <tr>
    <th></th>
    <th>Name</th>
    <th>Value</th>
    @if (asset && asset.dto == 'ObservableInventory_v1') {
    <th>Dynatrace Value</th>
    }
    <th></th>
  </tr>

  @for (row of tags; track row) {
  <tr>
    <td>
        @if (row.hasChanged || row.isDelete) {
        <mat-icon class="tab-icon" [title]="'Undo'" [class.hidden]="!!row.actual" (click)="resetTag(row)">undo</mat-icon>
        }
        @else {
        <mat-icon class="tab-icon" [title]="'Delete'" [class.hidden]="!!row.actual" (click)="removeTag(row)">close</mat-icon>
        }
    </td>

    <td #tagNameCell>
        @if (row.isNew) {
        <ejs-autocomplete #tagName style="width: 100%"
            [enabled]="!row.actual"
            [dataSource]="availableNames"
            [(value)]="row.name"
            (valueChange)="onTagSelected($event, tagName, row)"
            (focus)="onTagEditStart(tagName)" />
        }
        @else {
            <span class="readonly" [class.deleting]="row.isDelete">{{row.name}}</span>
        }
    </td>

    <td #tagValueCell>
        @if (row.isDelete) {
        <span class="readonly deleting">{{row.value}}</span>
        }
        @else {
        <ejs-autocomplete #tagValue style="width: 100%"
            [dataSource]="availableValues"
            [(value)]="row.value"
            (valueChange)="onValueSelected($event, tagValue, row)"
            (focus)="onValueEditStart(tagValue, row)" />
        }
    </td>

    @if (asset.dto == 'ObservableInventory_v1') {
    <td>
      @if(row.value == row.actual) {
      <span style="color: green;"> {{row.actual}} </span>
      } @else if(row.value == '' ) {
      <span style="color: #939339;"> {{row.actual}} </span>
      } @else if(row.value != row.actual) {
      <span style="color: red;"> {{row.actual}} </span>
      }
    </td><!-- Only for Obs Inv items -->
    }

    <td><!-- When user leaves a edit box, a validation will be done.
        The result will go into the tag (not submitted), and be shown
        here as a 'warning' icon with a tooltip. Otherwise, we show
        a 'save' or 'reset' icon.
        When 'Save' is clicked show 'saving' until tag has been saved.
        After that, show 'error' or a temporary checkmark.
     -->
      @if (!row.isValid) {
        <mat-icon class="tab-icon" [title]="row.error">warning</mat-icon>
      }
      @else if (row.isSaving) {
        <mat-icon class="tab-icon rotate">autorenew</mat-icon>
      }
      @else if (row.isDelete) {
        <mat-icon class="tab-icon" (click)="saveTag(row)">delete</mat-icon>
      }
      @else if (row.hasChanged == true || row.isNew == true) {
        <mat-icon class="tab-icon" (click)="saveTag(row)">save</mat-icon>
      }
      @else if (row.hasError) {
        <mat-icon class="tab-icon" [title]="row.error">error</mat-icon>
      }
      @else {
        &nbsp;
      }
    </td>
  </tr>
  }
</table>
<div class="actions">
    <div class="action" (click)="addTag()"><mat-icon>add</mat-icon><span>Add</span></div>
    <div class="action" [class.hidden]="!hasPendingChanges" (click)="saveAllTags()"><mat-icon>save</mat-icon><span>Save All</span></div>
</div>

